<template>
  <div>
    <div class="columns mb-0 has-background-white">
      <div class="column is-1">
        <label class="label is-size-7">VRM</label>
      </div>
      <div
        v-if="hasColumn('description')"
        :class="['column', marketDemand ? 'is-2' : 'is-4']"
      >
        <label class="label is-size-7">Description</label>
      </div>
      <div v-if="hasColumn('price')" class="column is-1" @click="sort('price')">
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'price' }"
        >
          <i :class="['fas', getIcon('price')]" />
          Listing price
        </a>
      </div>
      <div
        v-if="hasColumn('pricePosition')"
        class="column is-1"
        @click="sort('pricePosition')"
      >
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'pricePosition' }"
        >
          <i :class="['fas', getIcon('pricePosition')]" />
          Price position
        </a>
      </div>
      <div
        v-if="hasColumn('suggestedPrice')"
        class="column is-1"
        @click="sort('suggestedPrice')"
      >
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'suggestedPrice' }"
        >
          <i :class="['fas', getIcon('suggestedPrice')]" />
          <abbr
            v-tippy="{
              html: '#op-tooltip',
              interactive: true,
              placement: 'bottom'
            }"
          >
            Optimal price
          </abbr>
        </a>
      </div>
      <div
        v-if="hasColumn('mileage')"
        class="column is-1"
        @click="sort('mileage')"
      >
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'mileage' }"
        >
          <i :class="['fas', getIcon('mileage')]" />
          Mileage
        </a>
      </div>
      <div
        v-if="hasColumn('daysInStock')"
        class="column is-1"
        @click="sort('daysInStock')"
      >
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'daysInStock' }"
        >
          <i :class="['fas', getIcon('daysInStock')]" />
          Days in stock
        </a>
      </div>
      <div
        v-if="hasColumn('daysSinceLastPriceChange')"
        class="column is-1"
        @click="sort('daysSinceLastPriceChange')"
      >
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'daysSinceLastPriceChange' }"
        >
          <i :class="['fas', getIcon('daysSinceLastPriceChange')]" />
          Last price change
        </a>
      </div>
      <div v-if="hasColumn('vehicleStatus')" class="column is-1">
        <label class="label is-size-7">Vehicle status</label>
      </div>
      <div
        v-if="hasColumn('desirability') && marketDemand"
        class="column is-2"
        @click="sort('desirability')"
      >
        <a
          class="label is-size-7"
          :class="{ 'has-text-primary': sortBy === 'desirability' }"
        >
          <i :class="['fas', getIcon('desirability')]" /> Market demand
        </a>
      </div>
    </div>
    <div id="op-tooltip" class="is-hidden" v-tippy-html>
      <strong>Priced Well</strong> = Percayso Vehicle Intelligence valuation
      adjusted for days on sale
    </div>
  </div>
</template>

<script>
import * as stockviewListing from '../services/stockview-listing.service'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { snakeCase } from 'lodash'
export default {
  name: 'StockviewListingTableHeader',
  props: {
    properties: {
      type: Array,
      required: true,
      default: () => []
    },
    marketDemand: {
      type: Boolean,
      required: true
    }
  },
  computed: mapGetters('stockview/listings', ['sortBy', 'sortOrder']),
  methods: {
    ...mapMutations('stockview/listings', ['setSort']),
    ...mapActions('stockview/listings', ['getListings']),
    hasColumn(column) {
      return stockviewListing.hasColumn(this.properties, column)
    },
    getIcon(column) {
      if (this.sortBy !== column || !this.sortOrder) return 'fa-sort'
      return `fa-sort-${this.sortOrder === 'desc' ? 'down' : 'up'}`
    },
    sort(type) {
      const asc = this.sortBy !== type || !this.sortOrder
      const desc = this.sortOrder === 'asc'

      const order = asc ? 'asc' : desc ? 'desc' : null

      this.setSort({ type: order ? type : null, order })
      this.getListings()

      const mpProp = order ? `${snakeCase(type)}_${order}` : 'default'
      this.$mxp.track(`stockview_stock_sort_${mpProp}`)
    }
  }
}
</script>

<style lang="sass" scoped>
abbr
  border-bottom: 1px dotted currentColor
</style>
